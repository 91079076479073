import { storeToRefs } from "pinia";

import { usePaymentStore } from "@/stores/modules/payment";
import { usePurchaseStore } from "@/stores/modules/purchase";
import { useEvent } from "@/composables/useEvent";

import { useRouter } from "vue-router";

import { useModalHandler } from "@/plugins/useModalHandler";
import { useWaitingRoom } from "@/composables/useWaitingRoom";

export const usePayment = () => {
  const router = useRouter();

  const paymentStore = usePaymentStore();
  const {
    payment,
    paymentMethods,
    insuranceData,
    paymentData,
    ticketOfficeData,
    discountData,
    showCardForm,
    showOxxo,
    stripe
  } = storeToRefs(paymentStore);
  const purchaseStore = usePurchaseStore();
  const { isWhiteLabel, purchaseData, purchaseExtraData } = storeToRefs(
    purchaseStore
  );
  const { isIframe } = useEvent();
  const { isWaitingRoom } = useWaitingRoom();
  const { openErrorModal, closeBigLoader } = useModalHandler();

  const createPayments = async data => {
    try {
      await paymentStore.createPayments({
        ...data,
        ...purchaseExtraData.value,
        hb: isWhiteLabel.value
      });
    } catch (e) {
      openErrorModal({
        modal: {
          title: "wegow.payment.errors.error",
          btn_message: "wegow.error.accept"
        }
      });
    }
  };
  const confirmPayments = async data => {
    try {
      await paymentStore.confirmPayments({
        ...data,
        ...purchaseExtraData.value
      });
    } catch (e) {
      openErrorModal({
        modal: {
          title: "wegow.payment.errors.error",
          btn_message: "wegow.error.accept"
        }
      });
    }
  };

  const createFreePayments = async (buyer = {}) => {
    try {
      const paymentDataPayload = {
        details: {
          type: "free"
        },
        event_id: purchaseData.value.event.id,
        purchase: purchaseData.value.id,
        discount_code:
          purchaseData.value.discount_code || paymentData.value.discount_code,
        ...purchaseExtraData.value,
        hb: isWhiteLabel.value
      };
      // TODO: añadir buyer
      // if (buyer && this.$store.state.buyer.detail.id) {
      //   paymentData.buyer = this.$store.state.buyer.detail.id;
      // }
      await paymentStore.createPayments(paymentDataPayload);
      if (isWaitingRoom.value) {
        router.push({
          name: "WaitingRoomThankYouPage",
          params: { reference: purchaseData.value.reference }
        });
      } else if (isIframe.value) {
        router.push({
          name: "ThankYouPageWidget",
          params: { reference: purchaseData.value.reference }
        });
      } else {
        router.push({
          name: "ThankYouPage",
          params: { reference: purchaseData.value.reference }
        });
      }
    } catch (e) {
      closeBigLoader();
      openErrorModal({
        modal: {
          title: "wegow.payment.errors.error",
          btn_message: "wegow.error.accept"
        }
      });
    }
  };

  const createOfficePayments = async () => {
    try {
      // Buyer data
      let email = ticketOfficeData.value.email
        ? ticketOfficeData.value.email
        : "no-existing-email@wegow.com";
      let first_name = ticketOfficeData.value.first_name
        ? ticketOfficeData.value.first_name
        : "";
      let last_name = ticketOfficeData.value.last_name
        ? ticketOfficeData.value.last_name
        : "";
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        closeBigLoader();
        openErrorModal({
          modal: {
            title: "wegow.payment.emailError",
            btn_message: "wegow.error.accept"
          }
        });
        return;
      }
      let tickets = purchaseData.value.tickets.map(ticket => {
        return {
          first_name: first_name,
          last_name: last_name,
          id: ticket.id,
          email: email.toLowerCase()
        };
      });
      const paymentDataPayload = {
        details: {
          type: ticketOfficeData.value.cash
            ? "ticket_office_cash"
            : "ticket_office_cards"
        },
        event_id: purchaseData.value.event.id,
        purchase: purchaseData.value.id,
        // discount_code:
        //   purchaseData.value.discount_code || paymentData.value.discount_code,
        tickets: tickets,
        ticket_office: true,
        hb: isWhiteLabel.value
      };
      // TODO: Añadir correctamente la info de tipo de pago y email (ticketOfficeData)
      await paymentStore.createPayments(paymentDataPayload);

      router.push({
        name: "ThankYouPageOffice",
        params: { reference: purchaseData.value.reference }
      });
    } catch (e) {
      closeBigLoader();
      openErrorModal({
        modal: {
          title: "wegow.payment.errors.errorFree",
          btn_message: "wegow.error.accept"
        }
      });
    }
  };

  const getPayment = async data => {
    await paymentStore.getPayment(data);
  };

  const getPaymentMethods = async (eventId, ticketOffice) => {
    let extraUrl = "event=" + eventId;
    if (ticketOffice) {
      extraUrl += "&ticket_office=true";
    }
    await paymentStore.getPaymentMethods(extraUrl);
  };

  const validateDiscount = async discountCode => {
    let payload = {
      id: purchaseData.value.id,
      params: {
        discount_code: discountCode,
        event_id: purchaseData.value.event.id
      }
    };
    await paymentStore.validateDiscount(payload);
  };

  const deleteDiscount = async () => {
    discountData.value = {};
  };

  return {
    //! Properties
    payment,
    paymentMethods,
    paymentData,
    ticketOfficeData,
    discountData,
    showCardForm,
    showOxxo,
    stripe,
    insuranceData,
    //! Computed
    //! Metodos
    createPayments,
    confirmPayments,
    createFreePayments,
    createOfficePayments,
    getPaymentMethods,
    getPayment,
    validateDiscount,
    deleteDiscount
  };
};
