<template>
  <modal-template
    v-if="errorModal !== undefined"
    :size="errorModal.modal.size || 'intermedium'"
    class="z-20"
    :close="errorModal.modal.showClose !== false"
    :personalized-style-container="getPersonalizedBox"
    @close="closeModal"
  >
    <div class="row" :class="errorModal.modal.generalClass">
      <div
        v-if="errorModal.modal.imageType !== 'none' && !getPersonalizedBox"
        class="col-12"
      >
        <figure class="picture">
          <picture>
            <img
              :src="getModalImage"
              class="lazyload h-24"
              :alt="$t('wegow.error.altErrorIcon')"
            />
          </picture>
        </figure>
      </div>
      <div class="col-12 mB15 mT5">
        <h2
          v-if="errorModal.modal.title"
          id="modal-error-title"
          :style="getPersonalizedTitle"
          v-html="$t(errorModal.modal.title)"
        ></h2>
        <p
          v-if="errorModal.modal.description"
          id="modal-error-text"
          :style="getPersonalizedText"
          v-html="
            errorModal.modal.description.key
              ? $t(
                  errorModal.modal.description.key,
                  errorModal.modal.description.values
                )
              : $t(errorModal.modal.description)
          "
        ></p>
        <br />
        <div class="flex justify-center space-x-2">
          <wegow-button
            v-if="errorModal.modal.btn_message"
            :text="$t(errorModal.modal.btn_message)"
            size="xl"
            color="topaz-light"
            background="opacity"
            :styles="getPersonalizedButton"
            @click="close()"
          ></wegow-button>
          <wegow-button
            v-if="errorModal.modal.btn_red_message"
            :text="$t(errorModal.modal.btn_red_message)"
            size="xl"
            color="white"
            border="topaz"
            @click="closeRed()"
          ></wegow-button>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowButton from "@/components/general/forms/WegowButton";
import { useModalHandler } from "@/plugins/useModalHandler";
import { useIframeStyle } from "@/composables/useIframeStyle";
import { computed } from "vue";

const { errorModal, closeErrorModal } = useModalHandler();
const {
  getPersonalizedButton,
  getPersonalizedTitle,
  getPersonalizedText,
  getPersonalizedBox
} = useIframeStyle();

defineProps({
  size: {
    type: String,
    required: false,
    default: "small"
  }
});

const getModalImage = computed(() => {
  if (errorModal.value.modal.imageType === "accept") {
    return require("@/assets/images/ic-accept.svg");
  } else if (errorModal.value.modal.imageType === "accept-topaz") {
    return require("@/assets/images/ic-accept-topaz.svg");
  } else if (errorModal.value.modal.imageType === "info") {
    return require("@/assets/images/ic-info.svg");
  } else if (errorModal.value.modal.imageType === "waiting-list") {
    return require("@/assets/images/ic-history-yellow.svg");
  }
  return require("@/assets/images/ic-error-notice.svg");
});

// const descriptionText = computed(() => {
//   if(errorModal.modal.description.key) {
//     return t(
//                   errorModal.modal.description.key,
//                   errorModal.modal.description.values
//                 )
//   }
// });

const closeModal = () => {
  if (errorModal.value.modal.close_method) {
    errorModal.value.modal.close_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};

const close = () => {
  if (errorModal.value.modal.button_method) {
    errorModal.value.modal.button_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};
const closeRed = () => {
  if (errorModal.value.modal.button_red_method) {
    errorModal.value.modal.button_red_method();
    closeErrorModal();
  } else {
    closeErrorModal();
  }
};
</script>
<style lang="scss" scoped>
.picture img {
  margin: auto;
  max-width: 100px;
  max-height: 100px;
}
#modal-error-title {
  margin-top: 10px;
  font-size: 25px !important;
  transition: all 1s ease-out;
  ~ p {
    margin-top: 6px;
  }
}
</style>
