import httpClient from "@/api/axios.config";
import axios from "axios";
const s3Api = axios.create({
  baseURL: process.env.VUE_APP_S3_BASE_URL,
  timeout: 3000
});
export const apiService = {
  getS3File: resource => s3Api.get(`es${resource}`),
  get: (resource, params) => httpClient.get(resource, params),
  post: (resource, params, headers = {}) =>
    httpClient.post(resource, params, headers),
  patch: (resource, data, params) => httpClient.patch(resource, data, params),
  put: (resource, params) => httpClient.put(resource, params),
  delete: (resource, params) => httpClient.delete(resource, params)
};

export default apiService;
