import { defineStore } from "pinia";
import EventApi from "@/api/EventApi";

export const useEventStore = defineStore("event", {
  state: () => {
    return {
      details: [],
      events: [],
      eventsToArtist: [],
      eventsToArtistInCity: [],
      eventsToArtistInCountry: [],
      eventsToArtistPast: [],
      eventsToVenues: [],
      eventsRelatedToVenue: [],
      eventsRelatedToArtist: {},
      eventsRelatedToEvent: {},
      merchandising: [],
      tickets: [],
      isIframe: false,
      iframeStyles: {},
      // Seatsio
      configSeatsio: {},
      minTickets: {},
      available: {},
      pair: {},
      media: [],
      channels: [],
      defaultChannels: [],
      ticketOfficeAvailable: [],
      ticketOfficeReport: {}
    };
  },
  actions: {
    async getEvents(params) {
      this.events = await EventApi.getEvents(params);
    },
    async getDetails(slug, whiteLabel) {
      if (this.isIframe) {
        this.details = await EventApi.getDetailsIframe(slug, whiteLabel);
      } else {
        this.details = await EventApi.getDetails(slug);
      }
    },
    async addToWaitingList(payload) {
      await EventApi.addToWaitingList(payload);
    },
    async getTickets(params) {
      this.tickets = [];
      await this.getTicketsPage(params, 1);
      if (
        this.details.fan_to_fan_active &&
        this.tickets.filter(ticket => ticket.available || ticket.reserved)
          .length === 0
      ) {
        let weswapTickets = (
          await EventApi.getWeswapTickets({ event: this.details.id })
        ).transfer_tiers;
        for (const ticketsType of weswapTickets) {
          if (ticketsType.remaining > 0) {
            ticketsType.f2f = true;
            ticketsType.available = ticketsType.remaining;
            this.tickets.unshift(ticketsType);
          }
        }
        this.details.showWhiteList = true;
        this.details.weswapPurchase = true;
        // } else if (
        //   this.details.fan_to_fan_active &&
        //   this.tickets.find(ticket => ticket.available === 0)
        // ) {
        //   this.details.showWhiteList = true;
      } else {
        this.details.showWhiteList = false;
      }
    },
    async getTicketsPage(params, page) {
      let data = await EventApi.getTickets({ ...params, page: page });
      this.tickets = [...this.tickets, ...data.ticket_types];
      if (data.next_page) {
        await this.getTicketsPage(params, data.next_page);
      }
    },

    async getEventsRelatedToVenue(params) {
      this.eventsRelatedToVenue = (await EventApi.getEvents(params)).events;
    },
    async getEventsToVenueInfinite(params) {
      const events = (await EventApi.getEvents(params)).events;
      const result = this.eventsToVenues.concat(events);
      this.eventsToVenues = result;
    },
    async getEventsRelatedToArtist(params) {
      this.eventsRelatedToArtist = await EventApi.getEvents(params);
    },
    async getEventsToArtistInfinite(params) {
      const events = await EventApi.getEvents(params);
      const newEvents = events.events.map(event => {
        return new Proxy(event, {});
      });
      const result = this.eventsToArtist.events.concat(newEvents);
      this.eventsToArtist.events = result;
    },
    async getEventsToArtist(params) {
      this.eventsToArtist = await EventApi.getEvents(params);
    },
    async getCityArtistEvents(params) {
      this.eventsToArtistInCity = (await EventApi.getEvents(params)).events;
    },
    async getCountryEvents(params) {
      this.eventsToArtistInCountry = (await EventApi.getEvents(params)).events;
    },
    async getPreviousEvents(params) {
      this.eventsToArtistPast = (await EventApi.getEvents(params)).events;
    },
    async getEventsToVenues(params) {
      this.eventsToVenues = (await EventApi.getEvents(params)).events;
    },
    async getEventsRelatedToEvent(params) {
      this.eventsRelatedToEvent = await EventApi.getEvents(params);
    },
    async followCampaign(params) {
      await EventApi.followEvent(params);
    },
    async unfollowCampaign(params) {
      await EventApi.unfollowEvent(params);
    },
    async getMerchandising() {
      this.merchandising = [
        {
          id: 1,
          title: "Pablo Alborán - Vinilo La Cu4arta Hoja firmado",
          price: 21.0,
          gg: 1,
          img: "pablo-alboran-disco.png",
          cant: 0
        },
        {
          id: 2,
          title:
            "Pablo Alborán - Box Cd+2CD Directo+Tarjeta Rasca y Gana La Cu4rta Hoja",
          price: 25.0,
          gg: 1,
          img: "pabloalboranbox.png",
          cant: 0
        }
      ];
    },
    // Seatsio
    async getSeatsioConfig(params) {
      this.configSeatsio = await EventApi.getSeatsioConfig(params);
    },
    async getSeatsioTickets(params) {
      this.tickets = (await EventApi.getTickets(params)).ticket_types;

      this.minTickets = {};
      this.available = {};
      this.pair = {};
      this.tickets.forEach(item => {
        this.minTickets[item.external_id] = item.min_tickets;
        this.available[item.external_id] = item.available;
        this.pair[item.external_id] = item.pair;
      });
    },
    async followEvent(params) {
      await EventApi.followEvent(params);
    },
    async unfollowEvent(params) {
      await EventApi.unfollowEvent(params);
    },
    async getEventMedia(params) {
      this.media = await EventApi.getEventMedia(params);
    },
    async getEventChannels(params) {
      let data = await EventApi.getEventChannels(params);
      this.channels = data.channels;
      this.defaultChannels = data.channels.filter(item => {
        return item.default;
      });
    },
    async getTicketOfficeAvailable(validationCode) {
      this.ticketOfficeAvailable = (
        await EventApi.getTicketOfficeAvailable(validationCode)
      ).data.results;
    },
    async getTicketOfficeReport(params) {
      this.ticketOfficeReport = (
        await EventApi.getTicketOfficeReport(params)
      ).data;
    }
  }
});
