import { ProductStockError } from "@/plugins/errors/purchase-error";
import i18n from "@/main";

export const validateProductStock = (error = "") => {
  const { t } = i18n.global;
  if (
    error.status === 400 &&
    error.data.message === "Insuficient Stock in product"
  )
    throw new ProductStockError(t("wegow.purchase.errors.noStock"));
};
