export function formatCurrency(value, currency) {
  if (typeof currency !== "string") {
    currency = "EUR";
  }
  return new Intl.NumberFormat("es-es", { style: "currency", currency }).format(
    value
  );
}

export function deleteCurrency(amount) {
  const match = amount.match(/[\d.,]+/);
  if (match) {
    return parseFloat(match[0].replace(",", "."));
  }
  return null;
}
