import { storeToRefs } from "pinia";
import { useEventStore } from "@/stores/modules/event";
import { computed } from "vue";

export const useIframeStyle = () => {
  const eventStore = useEventStore();
  const { details, isIframe, iframeStyles } = storeToRefs(eventStore);
  return {
    //! Computeds
    getPersonalizedEventTitle: computed(() => {
      const {
        event_title_color,
        event_title_weight,
        event_title_size,
        titlesize,
        titleweight,
        title_color,
        font_color,
        background_color
      } = iframeStyles.value;

      return {
        backgroundColor: background_color,
        fontSize: event_title_color || titlesize,
        fontWeight: event_title_weight || titleweight,
        color: event_title_size || title_color || font_color
      };
    }),

    getPersonalizedVenueTitle: computed(() => {
      const {
        venue_color,
        venue_weight,
        venue_size,
        titlesize,
        titleweight,
        title_color,
        font_color
      } = iframeStyles.value;

      return {
        fontSize: venue_color || titlesize,
        fontWeight: venue_weight || titleweight,
        color: venue_size || title_color || font_color
      };
    }),

    getPersonalizedDateTitle: computed(() => {
      const {
        date_color,
        date_weight,
        date_size,
        titlesize,
        titleweight,
        subtitle_color,
        font_color
      } = iframeStyles.value;

      return {
        fontSize: date_color,
        fontWeight: date_weight,
        color: date_size || subtitle_color || font_color
      };
    }),

    getPersonalizedTitle: computed(() => {
      const {
        titlesize,
        titleweight,
        title_color,
        font_color
      } = iframeStyles.value;

      return {
        fontSize: titlesize,
        fontWeight: titleweight,
        color: title_color || font_color
      };
    }),

    getPersonalizedText: computed(() => {
      return { color: iframeStyles.value.font_color };
    }),

    getPersonalizedSubtitle: computed(() => {
      const { subtitle_color, font_color } = iframeStyles.value;

      return {
        color: subtitle_color || font_color
      };
    }),

    getPersonalizedPrice: computed(() => {
      const {
        price_color,
        font_color,
        pricesize,
        priceweight
      } = iframeStyles.value;

      return {
        color: price_color || font_color,
        fontSize: pricesize,
        fontWeight: priceweight
      };
    }),

    getPersonalizedAuxColor: computed(() => {
      const { aux_font_color, font_color } = iframeStyles.value;

      return {
        color: aux_font_color || font_color
      };
    }),

    getPersonalizedHr: computed(() => {
      const { hrcolor, hrweight } = iframeStyles.value;
      const borderWidth = hrweight || iframeStyles.value.hrweight || "1px";
      const urlColor = hrcolor || "";

      const borderTop = urlColor ? `${borderWidth} solid ${urlColor}` : "";

      return { borderTop };
    }),

    getPersonalizedTicketButton: computed(() => {
      const { button_color, selector_color, font_color } = iframeStyles.value;

      return {
        // backgroundColor: button_color,
        border: selector_color
          ? `1px solid ${selector_color} !important`
          : button_color,
        color: font_color
      };
    }),

    getPersonalizedButton: computed(() => {
      const { button_color, aux_font_color, font_color } = iframeStyles.value;

      const color = aux_font_color || font_color;
      const style = {
        backgroundColor: button_color,
        color: font_color
      };

      if (!button_color) {
        style.border = `2px solid ${color}`;
      }

      return style;
    }),

    getPaymentButton: computed(() => {
      const {
        payment_btn_background,
        payment_btn_color,
        font_color
      } = iframeStyles.value;

      const style = {
        backgroundColor: payment_btn_background,
        color: payment_btn_color || font_color
      };

      if (!payment_btn_background) {
        style.border = `2px solid ${style.color}`;
      }

      return style;
    }),

    getBannerButton: computed(() => {
      const { font_color } = iframeStyles.value;

      return {
        color: font_color,
        border: font_color ? `2px solid ${font_color}` : undefined
      };
    }),

    getInputStyle: computed(() => {
      const {
        input_color,
        font_color,
        input_background,
        box_color,
        input_border,
        card_border_color
      } = iframeStyles.value;

      const style = {
        color: input_color || font_color,
        backgroundColor: input_background || box_color
      };

      if (input_background) {
        style.border = input_border;
      } else if (card_border_color) {
        style.border = `2px solid ${card_border_color}`;
        // style.borderColor = card_border_color;
      }

      return style;
    }),

    getPersonalizedCheckbox: computed(() => {
      const { input_color, font_color } = iframeStyles.value;

      const style = {
        color: input_color || font_color
      };

      return style;
    }),

    getPersonalizedCheckboxBackground: computed(() => {
      const {
        input_background,
        box_color,
        input_border,
        card_border_color,
        checkbox_background
      } = iframeStyles.value;

      const style = {
        backgroundColor:
          (checkbox_background || input_background || box_color) + " !important"
      };

      if (input_background) {
        style.border = input_border + " !important";
      } else if (card_border_color) {
        style.border = `2px solid ${card_border_color} !important`;
        // style.borderColor = card_border_color;
      }

      return style;
    }),

    getPersonalizedCheckboxChecked: computed(() => {
      const { checkbox_checked_url } = iframeStyles.value;

      let style = {};
      if (checkbox_checked_url) {
        style.backgroundImage =
          "url(" + checkbox_checked_url + ")" + " !important";
      }

      return style;
    }),

    getPersonalizedBox: computed(() => {
      const { box_color, card_border_color } = iframeStyles.value;

      const style = {};

      style.backgroundColor = box_color;

      if (card_border_color) {
        style.border = `2px solid ${card_border_color}`;
        // style.borderColor = card_border_color;
      }

      return style;
    }),

    getPersonalizedBannerBox: computed(() => {
      const { banner_color, box_color, banner_text_color } = iframeStyles.value;

      const style = {};

      if (banner_color || box_color) {
        style.backgroundColor = banner_color || box_color;
        style.backgroundImage = "none";
      }
      style.color = banner_text_color;
      style.borderColor = banner_text_color;
      return style;
    }),

    getPersonalizedIcon: computed(() => {
      return { fill: iframeStyles.value.ticket_icon_color };
    }),

    getPersonalizedSteps: computed(() => {
      const { stepscolor, hb, aux_font_color } = iframeStyles.value;

      const style = {
        backgroundColor: stepscolor || aux_font_color
      };

      return style;
    }),
    getPersonalizedCardProduct: computed(() => {
      const { box_color, card_border_color, font_color } = iframeStyles.value;
      const style = {
        backgroundColor: box_color,
        borderColor: card_border_color,
        color: font_color
      };

      return style;
    })
  };
};
