import { storeToRefs } from "pinia";
import { useProductStore } from "@/stores/modules/product";

export const useProduct = () => {
  const productStore = useProductStore();
  const {
    product,
    products,
    productsTicketRequire,
    productsRequired,
    productsTicketNoRequire,
    purchaseProductsResume,
    productsPurchase,
    productDeletedId,
    totalProductPrice,
    totalProductTaxes,
    formattedTickets,
    error
  } = storeToRefs(productStore);

  const getProductsByTicket = async params => {
    await productStore.getProductsByTicket(params);
  };
  const clasifyProductsByTicketRequire = () => {
    productStore.clasifyProductsByTicketRequire();
  };
  const maxCantArray = product => {
    const cantArray = [];
    for (let i = 1; i <= product.max_by_ticket; i++) {
      cantArray.push({ value: i });
    }

    return cantArray;
  };
  const resetProductsList = () => {
    productStore.resetProductsList();
  };
  // Purchase Product
  const setProductsPurchase = (product, ticketType, ticketId) => {
    const productCopy = {
      id: product.id,
      name: product.name,
      quantity: product.cant || 1,
      ticket_id: ticketId,
      price: parseFloat(product.price) * (product.cant || 1),
      commision: parseFloat(product.commision) || 0,
      description: product.description,
      ticket_type: ticketType,
      max_by_ticket: product.max_by_ticket,
      additional_data: {},
      ticket_required: product.ticket_required
      // TODO: Additional_data
    };
    productStore.setProductsPurchase(productCopy);
  };
  const productPurchaseInit = () => {
    if (productsRequired.value.length > 0) {
      for (const require of productsRequired.value) {
        setProductsPurchase(require.product);
      }
    }
  };
  const createProductPurchaseData = product => {
    return productStore.createProductPurchaseData(product);
  };
  const addProductsToTickets = () => {
    productStore.addProductsToTickets();
  };
  const deleteProductPurchase = id => {
    productStore.deleteProductPurchase(id);
  };

  return {
    // Properties
    product,
    products,
    productsTicketRequire,
    productsRequired,
    productsTicketNoRequire,
    productsPurchase,
    formattedTickets,
    productDeletedId,
    error,
    // Getters
    totalProductPrice,
    totalProductTaxes,
    purchaseProductsResume,
    // Methods
    getProductsByTicket,
    clasifyProductsByTicketRequire,
    maxCantArray,
    setProductsPurchase,
    productPurchaseInit,
    createProductPurchaseData,
    addProductsToTickets,
    resetProductsList,
    deleteProductPurchase
  };
};
